<template>
  <div :id="`player-container-stream`" class="view-one-video">
    <div :id="`glplayer`" class="glplayer"></div>
  </div>
</template>

<script>

export default {
  name: "SingleVideoPlayer",
  props:{
    callbackPlay: {
      type: Function,
    },
    callBackReadyForDisplay: {
      type: Function
    },
    callBackPlayFinished: {
      type: Function
    },
    isMute: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      playerObj: null,
      currentSource: null,
      config: {
        accurateSeek: true,
        token:
          "base64:QXV0aG9yOmNoYW5neWFubG9uZ3xudW1iZXJ3b2xmLEdpdGh1YjpodHRwczovL2dpdGh1Yi5jb20vbnVtYmVyd29sZixFbWFpbDpwb3JzY2hlZ3QyM0Bmb3htYWlsLmNvbSxRUTo1MzEzNjU4NzIsSG9tZVBhZ2U6aHR0cDovL3h2aWRlby52aWRlbyxEaXNjb3JkOm51bWJlcndvbGYjODY5NCx3ZWNoYXI6bnVtYmVyd29sZjExLEJlaWppbmcsV29ya0luOkJhaWR1",
        extInfo: {
          moovStartFlag: true,
          readyShow: true,
          autoCrop: false,
          core: 0,
          coreProbePart: 0.1,
          ignoreAudio: 1,
          volume: 1,
        },
        currentVideoLink: null,
      },
      refTimeout: null,
    };
  },
  methods: {
    togglePlayPauseVideo(isPlay = null) {
      if (isPlay != null) {
        if (isPlay) {
          this.playVideo();
        } else {
          this.pauseVideo();
        }
      } else {
        if (this.playerObj.isPlaying()) {
          this.pauseVideo();
        } else {
          this.playVideo();
        }
      }
    },

    playVideo() {
        if(this.playerObj) {
            this.playerObj.play();
        }
        let videoTag = document.getElementById(`glplayer-vjs_html5_api`)
        if(videoTag) {
            videoTag.play();
        }
    },

    pauseVideo() {
        if(this.playerObj) {
            this.playerObj.pause();
            let videoTag = document.getElementById(`glplayer-vjs_html5_api`)
            if(videoTag) {
                videoTag.pause();
            }
        }
    },

    muteVideo() {
        this.toggleSoundVideo(0)
    },

    unmuteVideo() {
        this.toggleSoundVideo(1)
    },

    handleDispose() {
      if(this.playerObj && this.playerObj.isPlaying()){
        this.playerObj.pause()
      }
    },

    toggleSoundVideo(valueSound = null) {
      if (this.playerObj) {
        this.playerObj.setVoice(
          valueSound == null ? (this.playerObj.volume > 0 ? 0 : 1) : valueSound
        );
        let videoTag = document.getElementById(`glplayer-vjs_html5_api`)
        if(videoTag) {
          videoTag.volume = valueSound == null ? (this.playerObj.volume > 0 ? 0 : 1) : valueSound;
        }
      }
    },

    changeSourceVideo(source) {
        if(!source || source == this.currentSource) {
            return;
        }

        if (this.playerObj) {
            this.playerObj.release();
        }

        this.currentSource = source;
        this.config = {
            ...this.config,
            player: `glplayer`,
            width: "100%",
            height: "100%",
        };

        this.initH265webjs(source);
    },

    initH265webjs(videoURL) {
      this.playerObj = window.new265webjs(videoURL, this.config);
      let mediaInfo = null;

      this.playerObj.onLoadFinish = () => {
        mediaInfo = this.playerObj.mediaInfo();
        this.$emit('media-info', mediaInfo)

        this.toggleSoundVideo(this.isMute ? 0 : 1)
        if(this.refTimeout) {
          clearTimeout(this.refTimeout)
        }
        this.refTimeout = setTimeout(() => {
          this.playVideo()
        }, 500);
        // this.playerObj.play();
      };

      this.playerObj.setRenderScreen(true)

      this.playerObj.onPlayTime = (e) => {
          if(this.callbackPlay) {
            this.callbackPlay(e);
          }
      }

      this.playerObj.onReadyShowDone = () => {
          if(this.callBackReadyForDisplay) {
            this.callBackReadyForDisplay();
          }
      };

      this.playerObj.onPlayFinish = () => {
          if(this.callBackPlayFinished) {
            this.callBackPlayFinished();
          }
      };

      this.playerObj.do();
      return this.playerObj;
    },

    videoIsPause() {
        if(this.playerObj) {
            return !this.playerObj.isPlaying()
        }
        return true;
    },

    getMediaInfo() {
        if(this.playerObj) {
            return this.playerObj.mediaInfo();
        }
        return null;
    },

    seekVideo(duration) {
        if(this.playerObj) {
            this.playerObj.seek(duration)
        }
    },

    releaseVideo() {
        if(this.playerObj) {
            this.playerObj.release();
        }
    },

    handleVideo() {
      if (this.playerObj) {
        this.playerObj.release();
      }
      if (this.options.sources.length == 0 || !this.options.sources[0].src) {
        return;
      }
      var m3u8_url = this.options.sources[0].src;
      this.config = {
        ...this.config,
        player: `glplayer`,
        width: 250,
        height: 160,
      };
      if (this.typeVideo) {
        this.config = {
          ...this.config,
        };
      }
      this.initH265webjs(m3u8_url);
    },

  },
  beforeDestroy() {
    if (this.playerObj) {
      this.playerObj.release();
    }
  },
};
</script>

<style scoped>
.view-one-video {
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}
</style>

<style>
#player-container-stream .vjs-tech {
  width: 100% !important;
  height: 100% !important;
}

#player-container-stream video#glplayer-vjs {
  height: 0px;
}

#player-container-stream .glplayer {
  height: 100% !important;
  width: 100% !important;
  position: relative;
}

#player-container-stream .glplayer-vjs-dimensions {
  width: 100%;
  height: 100%;
}

#player-container-stream .glplayer canvas {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  object-fit: contain;
}
</style>
