<template>
    <div >
        <vs-popup class="cameraPackage" :title="$t('optionalCards.package')" :active.camel="openPopup">
            <vs-tabs color="primary" class="packeage" v-model="activeTab">
                <vs-tab :label="$t('menuPackage.signUpPackage')">
                    <package-register-camera
                        v-if="cameraIds"
                        @closeAndOpenPayment="closeAndOpenPayment"
                        @closePopupPackageCamera="closePopupPackageCamera"
                        :camera-id="cameraIds"
                        :organization-id=" organizationId">
                    </package-register-camera>
                </vs-tab>
                <vs-tab :label="$t('menuPackage.historyPackageTitle')">
                    <package-history :openPopup="openPopup" @closePopupPackageCamera="closeModal"
                                     :cameraValue="cameraIds"/>

                </vs-tab>
            </vs-tabs>
        </vs-popup>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import PackageHistory from './PackageHistory.vue'
import PackageRegisterCamera from "./PackageRegisterCamera";

export default {
    components: {
        PackageRegisterCamera,
        vSelect,
        PackageHistory
    },
    data() {
        return {
            activeTab: 0,
            checkPackageName: null,
            cameraIds: null,
            organizationId: null,
            components: {},
            organizationPackageList: [],
            permissionList: [],
            packageHistory: null,

            paymentData: null,
            isUpdatePackage: null,
        }

    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        closePopup: {
            type: Function
        },
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        onClickCloseButtonPackage(val) {
            this.cameraIds = null;
            this.activeTab = 0;
            if (val == true) {
                this.$eventBus.$emit('handle-reload-tree', true);
            }
            this.$emit('closePopupPackageCamera', val)
        },
        closeModal() {
            this.cameraIds = null;
            this.activeTab = 0;
            this.$emit('closePopupPackageCamera', false)
        },
        closeAndOpenPayment() {
            this.cameraIds = null;
            this.activeTab = 0;
            this.$emit('closeAndOpenPayment', false)
        },
        closePopupPackageCamera() {
            this.cameraIds = null;
            this.activeTab = 0;
            this.$emit('closePopupPackageCamera', false)
        }
    },
    created() {
    },
    mounted() {
        this.$eventBus.$on('package-camera', ($event) => {
            this.cameraIds = $event.id;
            this.organizationId = $event.organizationId;
        })
        const next = this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButtonPackage, false);
    }
}
</script>

<style lang="scss" scoped>
.colorTitle {
    color: #ff9f43 !important;
}

.iconFeather {
    width: 1.2rem;
    color: green;
}

.iconFeatherRed {
    width: 1.2rem;
    color: red;
}

.iconFeatherIs {
    width: 1.2rem;
    color: #ff9f43 !important;
}

.warningPackage {
    color: #ff9f43 !important;
}

.bg-grid-color-secondary {
    background-color: #dae1e729;
}

[dir] .m-auto {
    color: #2a3035;
}

.header-float {
    margin-left: 10px !important;
}

.vx-card__title > h4 {
    font-weight: bold;
}

@media (max-width: 1366px) {
    #page-user-list {
        margin-left: -13px;
    }
}
</style>

<style lang="scss">
.cameraPackage .vs-popup {
    width: 1300px;
    height: auto;
}
</style>
