<template>
    <div id="page-camera-list p-6">
        <div class="flex flex-wrap items-center">
            <div class="flex-grow">

                <span>{{$t('menuPackage.titlePackage')}}</span>

            </div>
            <!-- ITEMS PER PAGE -->
            <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                        v-model="searchQuery"
                        autocomplete="nope"
                        @input="updateSearchQuery" :placeholder="$t('menuPackage.search')"/>
        </div>
        <!-- AgGrid Table -->
        <ag-grid-vue style="height: 55vh"
                        ref="agGridTable"
                        :components="components"
                        :gridOptions="gridOptions"
                        class="ag-theme-material w-100 my-4 ag-grid-table"
                        :columnDefs="columnDefs"
                        :defaultColDef="defaultColDef"
                        :rowData="packageHistory"
                        :animateRows="true"
                        :pagination="true"
                        :localeText=" {noRowsToShow: this.$t('menuCamera.noRowsToShow')}"
                        :paginationPageSize="paginationPageSize"
                        :onGridReady="onGridReady"
                        :suppressPaginationPanel="true">
        </ag-grid-vue>
        <div class="vx-row items-center">
            <div class="vx-col md:w-1/2">
                <Pagination
                    :itemList="packageHistory"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
            </div>
            <div class="vx-col md:w-1/2">
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../../ui-elements/Pagination.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus";
import moment from 'moment'
import Vue from "vue";
import CellRendererPaymentStatus from "./cell-renderer/CellRendererPaymentStatus";

export default {
    components: {
        vSelect,
        AgGridVue,

        // Cell Renderer
        CellRendererActions,
        Pagination,
        CellRendererStatus,
        CellRendererPaymentStatus,
    },
    data() {
        return {
            cameraIds: null,
            organizationBranchId: null,
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                /*{
                    headerName: this.$t('menuPackage.cameraName'),
                    field: 'alias',
                    width: 80,
                },*/
                {
                    headerName: this.$t('menuPackage.softwarePackage'),
                    field: 'cameraPackageResponseDTO.packageName',
                    width: 100,
                },
                {
                    headerName: this.$t('menuPackage.time'),
                    field: 'time',
                    width: 80
                },
                {
                    headerName: this.$t('menuPackage.amount'),
                    field: 'amount',
                    width: 70
                },
                {
                    headerName: this.$t('menuPackage.paymentType'),
                    field: 'paymentType',
                    width: 70,
                    cellRendererFramework: 'CellRendererPaymentStatus'
                },
                {
                    headerName: this.$t('menuPackage.status'),
                    field: 'registerPackageStatus',
                    width: 70,
                    cellRendererFramework: 'CellRendererStatus'
                },
                {
                    headerName: this.$t('menuPackage.modifiedDate'),
                    field: 'modifiedDate',
                    width: 100
                },
                // {
                //     headerName: this.$t('menuPackage.actions'),
                //     field: 'actions',
                //     width: 60,
                //     cellRendererFramework: Vue.extend(CellRendererActions)
                // },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererStatus,
                CellRendererPaymentStatus
            },
            packageHistory: [],
            branchId: null,
        }
    },
    props: {
        closePopup: {
            type: Function
        },
        cameraValue: {

        },
        openPopup: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen(id) {
            if(id){
                this.$vs.loading();
                let url = '/camera-package-history/find-by-admin?cameraId=' + id;
                this.$crm.get(url).then((response) => {
                    this.packageHistory = response.data.map(x => {
                        x.cameraId = id;
                        x.amount = x.amount ? this.formatPrice(x.amount) : 0;
                        x.modifiedDate = moment(x.modifiedDate).format("DD/MM/YYYY HH:mm:ss")
                        return x;
                    }).sort((a, b) => b.id - a.id)
                    this.$vs.loading.close();
                    setTimeout(() => {
                        if (this.$store.state.windowWidth > 1024) {
                            this.gridApi.sizeColumnsToFit();
                        }
                    }, 100);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }
        },
        onClickCloseButtonPackageHistory(val) {
            if(val == true) {
                this.$eventBus.$emit('handle-reload-tree', true);
            }
            this.$emit('closePopupPackageCamera', val)
        }
    },
    created() {
        this.$eventBus.$on('i18n', (event) =>{
            this.columnDefs[0].headerName =  this.$t('menuPackage.softwarePackage');
            this.columnDefs[1].headerName =  this.$t('menuPackage.requestDate');
            this.columnDefs[2].headerName =  this.$t('menuPackage.price');
            this.columnDefs[3].headerName =  this.$t('menuPackage.status');
            this.columnDefs[4].headerName =  this.$t('menuPackage.actions');
        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        cameraValue: function (val) {
            if (val){
                this.initScreen(val);
                this.cameraIds = val;
            }
        },
        openPopup: function(val) {
            if (val && this.cameraValue){
                this.initScreen(this.cameraValue);
            }
        },
        '$store.state.isUpdateOrgPackageRequestPackage'(val) {
            if (val) {
                this.initScreen(val);
                this.$store.commit('UPDATE_REQUEST_PACKAGE', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
        if(this.cameraValue) {
            this.initScreen(this.cameraValue)
        }
        this.$eventBus.$on('close-modal-package', () => {
            this.onClickCloseButtonPackageHistory(false);
        })

    },
}
</script>

